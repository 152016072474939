import React, { useCallback, useState, useEffect } from 'react';
import { Button, mergeClasses, makeStyles, shorthands,Persona,Popover,PopoverSurface, PopoverTrigger,Text,tokens, } from '@fluentui/react-components';
import { useAppSelector} from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { useChat } from '../../../../libs/hooks/useChat';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breakpoints, customColors, customFonts } from '../../../../styles';
import { IGPT } from '../../../../libs/models/GPT';

const useClasses = makeStyles({
    button: {
        ...shorthands.border('1px', 'solid', 'transparent'),
        backgroundColor: 'transparent',
        fontWeight: 500,
        ...shorthands.padding('8px', '5px'),
        color: 'white',
        width: '45%',
        textAlign: 'left',
        ...shorthands.transition('all', '0.3s', 'ease'),
        '&:not(:disabled)': {
            '&:hover': {
                border: '1px solid white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                boxShadow: '0 4px 8px rgba(0, 63, 104, 0.2)',
                color: 'white',
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                boxShadow: '0 4px 8px rgba(0, 63, 104, 0.3)',
            },
            '&:focus': {
                ...shorthands.outline('none'),
                boxShadow: '0 0 0 2px rgba(0, 63, 104, 0.5)',
            },
        },
        '&:disabled': {
            backgroundColor: 'rgba(211, 211, 211, 0.2)',
            color: 'rgba(140, 140, 140, 0.2)',
            cursor: 'progress',
            opacity: 0.5,
        },
        display: 'block',
        marginLeft: '15px',
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '55%',
        ...Breakpoints.small({
            justifyContent: 'center',
        }),
        cursor: 'pointer',
        '&:hover': {
            border: '1px solid white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            boxShadow: '0 4px 8px rgba(0, 63, 104, 0.2)',
        },
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            boxShadow: '0 4px 8px rgba(0, 63, 104, 0.3)',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 2px rgba(0, 63, 104, 0.5)',
        },
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    title: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '12.5px',
        fontFamily: customFonts.Lato,
        color: customColors.white,
        lineHeight: tokens.lineHeightBase200,
    },
    popoverSurface: {
        display: 'none',
        ...Breakpoints.small({
            display: 'flex',
            flexDirection: 'column',
        }),
    },
    gptIcon: {
        '& > span': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'inline-flex',
        },
        '& > span > img': {
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
        },
    },
    icon: {
        '& > span': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'inline-flex',
        },
        '& > span > img': {
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
        },
    },
    small: {
        minWidth: '42px',
        ...shorthands.border('1px', 'solid', 'transparent'),
        backgroundColor: 'transparent',
        fontWeight: 500,
        color: 'white',
        width: '42px',
        height: '42px',
        paddingLeft: '20px',
        borderRadius: '50%',
        ...shorthands.transition('all', '0.3s', 'ease'),
        '&:not(:disabled)': {
            '&:hover': {
                border: '1px solid white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                boxShadow: '0 4px 8px rgba(0, 63, 104, 0.2)',
                color: 'white',
            },
            '&:active': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                boxShadow: '0 4px 8px rgba(0, 63, 104, 0.3)',
            },
            '&:focus': {
                ...shorthands.outline('none'),
                boxShadow: '0 0 0 2px rgba(0, 63, 104, 0.5)',
            },
        },
        '&:disabled': {
            backgroundColor: 'rgba(211, 211, 211, 0.2)',
            color: 'rgba(140, 140, 140, 0.2)',
            cursor: 'progress',
            opacity: 0.5,
        },
        marginBottom: '5px',
        marginLeft: '2px',
        
    }
});
interface RecentlyUsedGPTButtonProps {
    width: number;
}
const RecentlyUsedGPTs: React.FC<RecentlyUsedGPTButtonProps> = ({ width }) => {
    const classes = useClasses();
    const chat = useChat();
    const navigate = useNavigate();
    const { conversations } = useAppSelector((state: RootState) => state.conversations);
    const { userGPTs } = useSelector((state: RootState) => state.gpts);
    const [gptArray, setGptArray] = useState<IGPT[]>([]);
    const [gptIconNameArray, setGptIconNameArray] = useState<Record<string, string>>({});
    const [isCreatingChat, setIsCreatingChat] = useState(false);
    const chatCount: number = Object.keys(conversations).length;
    const ALLOWEDCHATS = 20;

    const createChat = useCallback(async (gpt: IGPT) => {
        setIsCreatingChat(true);
        var newChatId = await toast.promise(chat.createChat(gpt), {
            pending: {
                render: 'Creating chat...',
                position: 'top-right',
                className: classes.toastMessage,
            },
        });

        if (newChatId == 'Chat session cannot be created more than 20.')
            toast.error(
                'You have reached the maximum limit of 20 chat sessions. Please delete an existing chat to create a new one.',
                {
                    className: classes.toastMessage,
                },
            );
        else if (newChatId.startsWith('Unable to create new chat'))
            toast.error(null, {
                className: classes.toastMessage,
            });
        else {
            navigate(`/chat/${newChatId}`);
        }

        setIsCreatingChat(false);
    }, []);

    useEffect(() => {
        const fetchGptIcons = async () => {
            const sortedIds = Object.keys(conversations).sort((a, b) => {
                if (conversations[a].modifiedDate === undefined) {
                    return 1;
                }
                if (conversations[b].modifiedDate === undefined) {
                    return -1;
                }
                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                return conversations[a].modifiedDate! - conversations[b].modifiedDate!;
            });
    
            const newGptArray: IGPT[] = [];
            const newGptIconNameMap: Record<string, string> = {}; // Map to index icons by gptEndpoint
    
            for (const chatSession of sortedIds) {
                if (newGptArray.length >= 2) break;
                if (conversations[chatSession].type !== 0) {
                    const filteredGPTs = userGPTs.filter(
                        (gpt) => gpt.gptEndpoint === conversations[chatSession].gptEndpoint,
                    );
    
                    if (filteredGPTs[0]) {
                        const gpt = filteredGPTs[0];
                        if (!newGptArray.some((g) => g.gptEndpoint === gpt.gptEndpoint)) {
                            newGptArray.push(gpt);
    
                            try {
                                if (gpt.gptIconFileName) {
                                    const url = process.env.REACT_APP_CDN_IMAGE_URL + gpt.gptIconFileName;
                                    newGptIconNameMap[gpt.gptEndpoint] = url;
                                } else if (gpt.cardImageURL) {
                                    newGptIconNameMap[gpt.gptEndpoint] = gpt.cardImageURL;
                                }
                            } catch (error) {
                                console.error('Error fetching SAS URL:', error);
                            }
                        }
                    }
                }
            }
    
            setGptArray(newGptArray);
            setGptIconNameArray(newGptIconNameMap); // Optional if you still want an array
        };
    
        fetchGptIcons();
    }, [conversations, userGPTs]); // Include dependencies

    return (
        <div>
            {gptArray.map((gpt, index) => (
                <Popover
                    key={index}
                    openOnHover={true}
                    mouseLeaveDelay={0}
                    positioning={{
                        position: 'after',
                        autoSize: 'width',
                    }}
                >
                    <PopoverTrigger disableButtonEnhancement>
                        <Button
                            className={width > 64 ? mergeClasses(classes.button, classes.root) : classes.small}
                            onClick={() => createChat(gpt)}
                            data-testid={`gptButton-${index}`}
                            disabled={isCreatingChat || chatCount == ALLOWEDCHATS}
                            style={
                                isCreatingChat
                                    ? {
                                          cursor: 'progress',
                                          opacity: 0.5,
                                          pointerEvents: 'visible',
                                          width: `${width - 30}px`,
                                      }
                                    : { width: `${width - 30}px` }
                            }
                        >
                            <Persona
                                className={classes.icon}
                                avatar={
                                    width > 64
                                        ? { image: { src: gptIconNameArray[gpt.gptEndpoint] }, shape: 'square' }
                                        : { image: { src: gptIconNameArray[gpt.gptEndpoint] }, shape: 'circular' }
                                }
                            />
                            {width > 64 && <Text className={classes.title}>{gpt.name}</Text>}
                        </Button>
                    </PopoverTrigger>
                    <PopoverSurface className={classes.popoverSurface}>
                        <Text weight="bold">{gpt.name}</Text>
                    </PopoverSurface>
                </Popover>
            ))}
        </div>
    );
};


export default RecentlyUsedGPTs;
