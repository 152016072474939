import * as signalR from '@microsoft/signalr';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AlertType } from '../../../libs/models/AlertType';
import { addAlert } from '../app/appSlice';
import { IChatMessage } from './../../../libs/models/ChatMessage';
import { StoreMiddlewareAPI, getSelectedChatID } from './../../app/store';
import SignalRHubConnection from './signalRHubConnection';
import { DeleteChatState } from '../conversations/DeleteChatState';

interface SignalRAction extends AnyAction {
    payload: {
        message?: IChatMessage;
        userId?: string;
        isTyping?: boolean;
        id?: string;
        chatId?: string;
    };
}

export const signalRMiddleware = (store: StoreMiddlewareAPI) => {
    const signalRHubConnection = new SignalRHubConnection(store);

    // Establish connection with assistant relay hub on initialization
    signalRHubConnection.getOrCreateHubConnection(
        process.env.REACT_APP_ASSISTANT_SIGNALR_URI as string,
        '/assistantrelayhub'
    );
    
    // New helper to handle hub invocations with error handling
    const invokeHubMethod = async (
        hubConnection: signalR.HubConnection | undefined,
        methodName: string,
        ...args: any[]
    ) => {
        if (!hubConnection) return;

        if (hubConnection.state === signalR.HubConnectionState.Connected) {
            try {
                await hubConnection.invoke(methodName, ...args);
            } catch (error) {
                const errorMessage = String(error);
                console.error(`Error invoking ${methodName}:`, errorMessage);
                
                if (errorMessage.includes("'Connected' State")) {
                    store.dispatch(addAlert({
                        message: 'Connection lost. Attempting to reconnect...',
                        type: AlertType.Warning
                    }));
                }
            }
        } else if(hubConnection.state === signalR.HubConnectionState.Disconnected) {
            try {
                await hubConnection.start();
                console.log('Reconnected to the hub.');
                await hubConnection.invoke(methodName, ...args);
            } catch (reconnectError) {
                console.error('Reconnection attempt failed:', reconnectError);
            }
        }
    };

    return (next: Dispatch) => (action: SignalRAction) => {
        const result = next(action);
        const state = store.getState();
        const selectedId = state.conversations.selectedId;
        const conversations = state.conversations.conversations;
        
        if (!selectedId || !conversations[selectedId]) return result;

        // Get all unique endpoints for the selected conversation
        const endpoints = new Set<string>();
        const conversation = conversations[selectedId];
        
        if (conversation.gptEndpoint) {
            endpoints.add(conversation.gptEndpoint);
        }

        // Handle different action types
        const handleAction = async () => {
            for (const endpoint of endpoints) {
                const hubConnection = signalRHubConnection.getOrCreateHubConnection(endpoint);

                switch (action.type) {
                    case 'conversations/addMessageToConversationFromUser':
                        await invokeHubMethod(
                            hubConnection,
                            'SendMessageAsync',
                            getSelectedChatID(),
                            state.app.activeUserInfo?.id,
                            action.payload.message
                        );
                        break;

                    case 'conversations/updateUserIsTyping':
                        await invokeHubMethod(
                            hubConnection,
                            'SendUserTypingStateAsync',
                            getSelectedChatID(),
                            action.payload.userId,
                            action.payload.isTyping
                        );
                        break;

                    case 'conversations/setConversations':
                    case 'conversations/addConversation':
                        const chatIds = action.type === 'conversations/setConversations' 
                            ? Object.keys(action.payload)
                            : [action.payload.id];

                        for (const id of chatIds) {
                            await invokeHubMethod(
                                hubConnection,
                                'AddClientToGroupAsync',
                                id
                            );
                        }
                        break;
                    case 'conversations/deleteConversation':
                        const chatIdToDelete = (action.payload as DeleteChatState).chatId;
                        if (chatIdToDelete.includes('thread_')) {
                            await invokeHubMethod(
                            hubConnection,
                            'RemoveClientFromGroupAsync',
                            chatIdToDelete
                        );
                        }
                        break;
                }
            }
        };

        handleAction().catch(error => {
            console.error('Error in SignalR middleware:', error);
            store.dispatch(addAlert({
                message: 'Error processing action. Please try again.',
                type: AlertType.Error
            }));
        });

        return result;
    };
};