import { Button, Input, Text, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { Search24Regular } from '@fluentui/react-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as Arrow } from '../../assets/arrow-smallupward-icon-black-rgb.svg';
import { IGPT } from '../../libs/models/GPT';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { removeAlert } from '../../redux/features/app/appSlice';
import { customColors, customFonts } from '../../styles';
import RatingFeedback from '../rating/RatingFeedback';
import { ExploreGPTTile } from './ExploreGPTTile';
import { useGPT } from '../../libs/hooks/useGPT';
import GPTOverview from '../custom-gpt/GPTOverview';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        ...shorthands.padding('60px', '10px'),
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorNeutralForeground1,
        ...shorthands.overflow('auto'),
    },
    header: {
        ...shorthands.margin(tokens.spacingVerticalM, tokens.spacingHorizontalL),
        backgroundColor: tokens.colorNeutralBackground1,
        textAlign: 'center',
        height: '150px',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
    },
    title: {
        marginTop: '150px',
        marginBottom: '20px',
        fontSize: 'clamp(2rem, 8vw, 5em)',
        fontWeight: '500',
        display: 'flex',
        justifyContent: 'center',
        color: customColors.summarizeButtonDarker,
        fontFamily: customFonts.TitleFont,
    },
    subtitle: {
        // fontSize: '2em',
        fontSize: 'clamp(1rem, 5vw, 2em)',
        textAlign: 'center',
        marginBottom: '30px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '300',
        color: customColors.headerLightGray,
        fontFamily: customFonts.TitleFont,
    },
    searchBar: {
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        margin: '0 auto',
        marginLeft: '-140px',
        marginBottom: '30px',
        width: '120%',
        height: '52px',
        '@media screen and (max-width: 1150px)': {
            width: '100%',
            display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 0 0 0px'
        },

    },
    tabContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke1),
    },
    tab: {
        ...shorthands.padding('10px', '20px'),
        ...shorthands.margin('0', '5px'),
        backgroundColor: '#fffff',
        cursor: 'pointer',
        ...shorthands.borderRadius('5px'),
        ...shorthands.border('none'),
        color: tokens.colorNeutralForeground1,
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    featuredSection: {
        textAlign: 'left',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media screen and (min-width: 1151px) and (max-width: 1674px)': {
            width: '85%'
        },
        '@media screen and (max-width: 1150px)': {
            width: '100%'
        },
    },
    gptList: {
        marginTop: '20px',
        display: 'flex',
        //justifyContent: 'space-around',
        justifyContent: 'space-evenly',
        // alignItems: 'center',
        flexWrap: 'wrap',
        width: '90%',
        height: '100%',
        ...shorthands.overflow('auto'),
        '@media screen and (min-width: 1151px) and (max-width: 1674px)': {
            width: '100%',
            justifyContent: 'space-evenly',
        },
        '@media screen and (max-width: 1150px)': {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    exploreTileWrapper: {
        // minWidth: '350px',
        // width: '350px',
        // maxWidth: '350px',
        marginBottom: '10px',
        height: '150px',
        '@media screen and (max-width: 1150px)': {
            marginLeft: '20px',
            marginRight: '20px'
        },
    },
    arrowUnfocused: {
        fill: customColors.darkNavy,
        stroke: customColors.darkNavy,
    },
    arrowFocused: {
        fill: customColors.protivitiOrange,
        stroke: customColors.protivitiOrange,
    },
    buttonUnfocused: {
        justifySelf: 'center',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', '#979797'),
        //...shorthands.margin('5px'),
        maxHeight: '120px',
        alignSelf: 'center',
    },
    buttonFocused: {
        justifySelf: 'center',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),
        ...shorthands.margin('5px'),
        maxHeight: '120px',
        alignSelf: 'center',
    },
});

export const ExploreGPTWindow: React.FC = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const gptService = useGPT();
    const [searchQuery, setSearchQuery] = useState('');
    const [gpts, setGpts] = useState<IGPT[]>([]);
    const [isRatingOpen, setIsRatingOpen] = useState(false);
    const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const [isFocused, setIsFocused] = React.useState(false);
    const [userId] = useState<string | undefined>(activeUserInfo?.id);
    const [customGPTId, setCustomGPTId] = useState<string | undefined>(undefined);
    userId: activeUserInfo?.id as string;

    function sortGPTs(GPTs: IGPT[]) {
        const gptsSorted = [...GPTs];
        gptsSorted.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        return gptsSorted;
    }

    useEffect(() => {
        setGpts(sortGPTs(userGPTs));
        dispatch(removeAlert(0));
    }, []);

    const filteredGPTs = useMemo(
        () =>
            gpts.filter(
                (gpt) =>
                    gpt.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    gpt.cardShortDescription.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    (gpt.tag.toLowerCase().includes(searchQuery.toLowerCase()) && gpt.isRegistered && !gpt.isDeleted),
            ),
        [gpts, searchQuery],
    );

    // Function to update the GPTs state
    const updateGpts = async () => {
        const updatedGpts = await gptService.getGPTs();
        setGpts(sortGPTs(updatedGpts));
    };

    /*const handleButtonClick = (event: React.MouseEvent, gptId: string) => {
        event.stopPropagation();
        //  userId = activeUserInfo?.id as string;
        setIsRatingOpen(true);
        //setUserId(userId);
        setCustomGPTId(gptId);
    };*/

    const handleCloseRating = () => {
        setIsRatingOpen(false);
    };

    const handleLearnMore = (gptId: string) => {
        setCustomGPTId(gptId);
        setIsLearnMoreOpen(true);
    };

    const handleCloseLearnMore = () => {
        setIsLearnMoreOpen(false);
    };

    return (
        <div className={classes.root}>
            <div
                className={classes.header}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}
            >
                <Text className={classes.title}>
                    Protiviti Tailored GPTs
                </Text>
                <Text as="p" className={classes.subtitle}>
                    Explore Protiviti's custom GPTs built upon our intellectual property
                </Text>

                <div className={classes.searchBar}>
                    <Input
                        style={{ width: '50%', height: '50px' }}
                        contentBefore={<Search24Regular />}
                        contentAfter={
                            <Button
                                appearance="transparent"
                                className={isFocused ? classes.buttonFocused : classes.buttonUnfocused}
                                style={{
                                    transition: 'all 0.3s ease',
                                    marginRight: '-2px',
                                }}
                                icon={<Arrow className={isFocused ? classes.arrowFocused : classes.arrowUnfocused} />}
                            />
                        }
                        placeholder="Search GPTs"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onFocus={() => {
                            setIsFocused(true);
                        }}
                        onBlur={() => {
                            setIsFocused(false);
                        }}
                    />
                </div>
            </div>

            {/* TODO: IMPLEMENT FUNCTIONAL TABS -- PLEASE DO NOT REMOVE THE COMMENTED CODE BELOW
             {
                <div className={classes.tabContainer} style={{ width: '50%' }}>
                    <Button
                        className={classes.tab}
                        style={{ backgroundColor: tokens.colorBrandBackgroundHover, color: 'white' }}
                    >
                        Top Picks
                    </Button>
                    <Button className={classes.tab}>IAFA</Button>
                    <Button className={classes.tab}>TC</Button>
                    <Button className={classes.tab}>R&C</Button>
                    <Button className={classes.tab}>Digital</Button>
                    <Button className={classes.tab}>General</Button>
                </div>
            } */}

            <div className={classes.tabContent}>
                <div className={classes.featuredSection}>
                    <div
                        className={classes.header}
                        style={{
                            width: '75%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignContent: 'center',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            verticalAlign: 'top',
                        }}
                    >
                        <Text
                            as="h1"
                            style={{
                                marginTop: '150px',
                                marginBottom: '2px',
                                fontSize: '25px',
                                fontWeight: '500',
                                color: customColors.summarizeButtonDarker,
                                fontFamily: customFonts.TitleFont,
                            }}
                        >
                            Custom Protiviti GPTs
                        </Text>
                        <Text
                            as="p"
                            style={{
                                fontSize: '18px',
                                fontWeight: '600',
                                color: customColors.headerLightGray,
                                fontFamily: customFonts.TitleFont,
                            }}
                        >
                            (more on the way...)
                        </Text>
                    </div>
                    <div className={classes.gptList}>
                        {filteredGPTs.map(
                            (gpt) =>
                                gpt.id !== process.env.REACT_APP_DEFAULT_PROGPT &&
                                gpt.id !== process.env.REACT_APP_DEFAULT_ASSISTANTGPT && (
                                    <div key={gpt.id} className={classes.exploreTileWrapper}>
                                        <ExploreGPTTile
                                            gpt={gpt}
                                            isFunctionTriggered={gpt.gptEndpoint.includes('function')}
                                            //handleButtonClick={(event) => handleButtonClick(event, gpt.id)}
                                            handleLearnMore={handleLearnMore}
                                        />
                                    </div>
                                ),
                        )}
                    </div>
                    {isLearnMoreOpen && (
                        <GPTOverview
                            gptId={customGPTId}
                            isModal={true}
                            isOpen={isLearnMoreOpen}
                            onClose={handleCloseLearnMore}
                        />
                    )}
                    {isRatingOpen && (
                        <RatingFeedback
                            isOpen={isRatingOpen}
                            onClose={handleCloseRating}
                            userId={userId}
                            gptId={customGPTId}
                            updateGpts={updateGpts}
                        />
                    )}
                </div>
                <ToastContainer />
            </div>
        </div>
    );
};
